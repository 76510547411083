import React from "react";
// import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
function HeroVideo({ urlVideo, title, texts }) {
    // const { rpdata } = useContext(GlobalDataContext);
    return (
        <div>
            <div className="w-full relative content_video flex justify-center ">
                <video

                    playsInline
                    autoPlay
                    muted
                    loop
                    className="w-full md:h-full object-cover"
                >
                    <source
                        src={urlVideo}
                        type="video/mp4"
                    />
                </video>
                <div className="grid md:grid-cols-2 grid-cols-1 md:w-4/5 mx-auto absolute bottom-[30px] md:bottom-[100px] lg:bottom-[90px] md:text-start text-start text-white">
                    <div className="w-full text-center md:text-start p-4">
                        <h1 className="text-[30px] md:text-[3em] lg:text-[4em]">{title}</h1>
                        <p>{texts}</p>
                        <ButtonContent btnStyle="three" />
                    </div>
                    <div className="flex justify-center items-center">
                        <img src={"https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Años%20de%20experiencia%2F16%20Años.png?alt=media&token=6065fdd9-ffa7-4d8c-9826-d08764f65a69"}
                            alt="chapayears"
                            loading="lazy"
                            className="h-[155px] w-[151px] md:h-[310px] md:w-[303px] object-cover"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default HeroVideo;